import * as Google from 'expo-auth-session/providers/google';
import useForm from 'hooks/useForm';
import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import { View, Text, Image, StyleSheet, Pressable } from 'react-native';

import { gmailConfig } from './configs';

import authStore from 'store/authStore';

import { styles } from 'screens/MobileNavigation/SignInScreen/styles';
import { WebStyles } from 'screens/WebNavigation/WebStyles';

import {
 sendOtp,
 signInUserByGmail,
 signInUserByFb,
 signInUserByApple,
} from 'services/authService';
import { signInUser } from 'services/index';

import ModalWrapper from 'components/modals/ModalWrapper';
import VerifyModal from 'components/modals/VerifyModal';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import SocialIcon from 'components/reusable/SocialIcon';
import Toast from 'components/reusable/Toast';
import { SigninSchema } from 'components/validations/schema';

import {
 getAppleCredentials,
 getFacebookUserData,
 getPermissions,
 getUser,
} from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { storageSet } from 'utils/mmkvStorage';
import { isIOS, isMobile, isWeb } from 'utils/platform';
import { getColor } from 'utils/settings';
import {
 SIGNIN_WITH_APPLE,
 SIGNIN_WITH_FB,
 SIGNIN_WITH_GOOGLE,
} from 'utils/uris';

const LoginForm = ({ navigation, signInToken }) => {
 const [request, response, promptAsync] = Google.useAuthRequest(
  gmailConfig,
  {}
 );

 let defaultValues = {};

 if (process.env.NODE_ENV === 'development' && isWeb) {
  defaultValues = { email: 'jeko@4twiggers.com', password: 'Admin123!' };
 } else if (process.env.NODE_ENV === 'development' && !isWeb) {
  defaultValues = { email: 'jeko@4twiggers.com', password: 'Admin123!' };
 } else {
  defaultValues = { email: '', password: '' };
 }

 const { control, handleSubmit, errors, clearErrors, isSubmitting, setError } =
  useForm(SigninSchema, defaultValues);

 useEffect(() => {
  if (isMobile) {
   navigation.addListener('beforeRemove', () => {
    clearErrors();
   });
  }
 }, []);

 const getFbData = (profile) => {
  signInUserByFb(profile.email).then((response) => {
   if (response.type === 'success') {
    const { token } = response;
    storageSet('AccessToken', token);
    storageSet('userId', jwt_decode(token).userId);
    Defaults.userId = jwt_decode(token).userId;
    authStore.isLoggedIn = true;
    getPermissions(Defaults.userId);
    getUser();
    navigation.replace('Home');
   } else {
    Defaults.toast.show(<Toast withError={true} message={response.message} />);
    setError('email', { type: 'custom', message: response.message });
   }
  });
 };

 const onSigninWithFb = async () => {
  const uri = `${SIGNIN_WITH_FB}?state=login`;

  if (isWeb) {
   window.open(uri, '_self');
  } else {
   const data = await getFacebookUserData();

   getFbData(data);
  }
 };

 const onSigninWithGoogle = async () => {
  const uri = `${SIGNIN_WITH_GOOGLE}?state=login`;
  if (isWeb) {
   window.open(uri, '_self');
  } else {
   promptAsync(request.url);
  }
 };

 const getGmailUser = async (token) => {
  var decoded = jwt_decode(token);

  const response = await signInUserByGmail(decoded.email);

  if (response.type === 'success') {
   const { token } = response;
   storageSet('AccessToken', token);
   storageSet('userId', jwt_decode(token).userId);
   Defaults.userId = jwt_decode(token).userId;
   authStore.isLoggedIn = true;
   getPermissions(Defaults.userId);
   getUser();
   navigation.replace('Home');
  } else {
   setError('email', { type: 'custom', message: response.message });
  }
 };

 useEffect(() => {
  if (response?.type === 'success') {
   getGmailUser(response.authentication.idToken);
  }
 }, [response]);

 const onSubmit = async (values) => {
  const response = await signInUser(values.email, values.password);
  if (
   isWeb &&
   response.type === 'error' &&
   response.message === 'Email not verified'
  ) {
   const res = await sendOtp(values.email);
   if (res.type === 'success') {
    Defaults.Modal.show(
     <ModalWrapper customStyles={WebStyles.modalWrapperStyles}>
      <VerifyModal
       navigation={navigation}
       userEmailForWeb={values.email}
       tokenForWeb={response.token}
       refreshTokenForWeb={response.refreshToken}
      />
     </ModalWrapper>
    );
   }
  }

  if (response.type === 'success') {
   const { token, refreshToken } = response.data;
   storageSet('AccessToken', token);
   storageSet('RefreshToken', refreshToken);
   storageSet('userId', jwt_decode(token).userId);
   Defaults.userId = jwt_decode(token).userId;
   authStore.isLoggedIn = true;
   getPermissions(Defaults.userId);
   if (isMobile) {
    getUser();
    navigation.replace('Home');
   }
  } else {
   setError('email', { message: response.message });
  }
 };

 const onApple = async () => {
  if (isWeb) {
   window.open(SIGNIN_WITH_APPLE, '_self');
  } else {
   const appleRes = await getAppleCredentials();

   const user = jwt_decode(appleRes.identityToken);
   const res = await signInUserByApple(user.email);

   if (res.type === 'success') {
    const user2 = jwt_decode(res.token);
    storageSet('AccessToken', res.token);
    storageSet('userId', user2.userId);
    Defaults.userId = user2.userId;
    authStore.isLoggedIn = true;
    getPermissions(Defaults.userId);
    getUser();
    isMobile && navigation.replace('Home');
   } else {
    Defaults.toast.show(<Toast withError={true} message={res.message} />);
    setError('email', { type: 'custom', message: res.message });
   }
  }
 };

 if (signInToken) {
  authStore.profile = jwt_decode(signInToken);
  storageSet('AccessToken', signInToken);
  storageSet('RefreshToken', signInToken);
  authStore.isLoggedIn = true;
  Defaults.userId = jwt_decode(signInToken).userId;
  getPermissions(Defaults.userId);
  storageSet('userId', jwt_decode(signInToken).userId);
  getUser();
 }

 return (
  <View
   style={isWeb ? { justifyContent: 'space-between', flex: 1 } : { flex: 1 }}>
   <View>
    <Text style={isWeb ? WebStyles.WebHeadText : styles.mainText}>Log In</Text>
    <View style={{ marginBottom: 24 }}>
     <ControllerInput
      name='email'
      control={control}
      iconName='envelope'
      placeholder='Email Address'
      keyboardType='email-address'
      error={errors.email?.message}
      inputStyles={{ marginBottom: 10 }}
     />
    </View>

    <View style={{ marginBottom: 32 }}>
     <ControllerInput
      withEyeIcon
      name='password'
      control={control}
      iconName='lockOn'
      placeholder='Password'
      secureTextEntry
      error={errors.password?.message}
      inputStyles={{ marginBottom: 10 }}
     />

     {isMobile && (
      <Text
       style={styles.forgorPassStyle}
       onPress={() => navigation.navigate('ResetPassword')}>
       Forgot Password?
      </Text>
     )}
    </View>

    <Button
     title='Log in'
     disabled={isSubmitting}
     bgColor={getColor('NORMAL_GREEN')}
     onPress={handleSubmit(onSubmit)}
    />

    {isWeb && (
     <View style={{ alignSelf: 'center', marginTop: 24 }}>
      <Pressable onPress={() => navigation.navigate('PasswordReset')}>
       <Text style={[styles.bottomText, { marginBottom: 32 }]}>
        Forgot Password?
       </Text>
      </Pressable>
     </View>
    )}

    <View style={[styles.signUpWith, isWeb && { marginTop: 0 }]}>
     <View style={styles.hr} />
     <Text style={styles.signUpWithText}>OR log in with</Text>
     <View style={styles.hr} />
    </View>

    <View>
     <View
      style={
       isWeb ? { flexDirection: 'row', justifyContent: 'space-between' } : {}
      }>
      <View style={{ width: isWeb ? '48%' : '100%', marginBottom: 12 }}>
       <Pressable onPress={onSigninWithGoogle}>
        <SocialIcon text='Google'>
         <Image
          style={{ width: 25, height: 25 }}
          source={require('../../../assets/images/gmail.png')}
         />
        </SocialIcon>
       </Pressable>
      </View>
      <View style={{ width: isWeb ? '48%' : '100%', marginBottom: 12 }}>
       <Pressable onPress={onSigninWithFb}>
        <SocialIcon text='Facebook'>
         <Image
          style={{ width: 25, height: 25 }}
          source={require('../../../assets/images/facebook.png')}
         />
        </SocialIcon>
       </Pressable>
      </View>
     </View>
     {(isIOS || isWeb) && (
      <View style={{ width: '100%' }}>
       <Pressable onPress={onApple}>
        <SocialIcon text='Sign in with Apple'>
         <Text style={{ fontSize: 22 }}></Text>
        </SocialIcon>
       </Pressable>
      </View>
     )}
    </View>
   </View>

   <View
    style={isWeb ? {} : { flex: 1, marginTop: 24, justifyContent: 'center' }}>
    <Text
     style={StyleSheet.flatten([
      styles.bottomText,
      isWeb ? { marginTop: 0, marginBottom: 0 } : {},
     ])}>
     First time user?{' '}
     <Text
      style={{ color: getColor('NORMAL_ORANGE') }}
      onPress={() => navigation.navigate('Signup')}>
      Create Account
     </Text>
    </Text>
   </View>
  </View>
 );
};

export default LoginForm;
