import React from 'react';
import { Controller } from 'react-hook-form';

import Input from 'components/reusable/Input';

import { getColor } from 'utils/settings';

const ControllerInput = ({
 name,
 placeholder,
 iconName,
 control,
 error,
 withEyeIcon,
 keyboardType,
 withIcon,
 inputStyles,
 secureTextEntry,
 otherStyles,
 hasDropDown,
}) => {
 return (
  <Controller
   control={control}
   name={name}
   render={({ field: { onChange, onBlur, value } }) => {
    return (
     <Input
      onChangeText={onChange}
      value={value}
      withEyeIcon={withEyeIcon}
      onBlur={onBlur}
      placeholder={placeholder}
      error={error}
      keyboardType={keyboardType}
      secureTextEntry={secureTextEntry}
      withIcon={withIcon}
      iconName={iconName}
      style={inputStyles}
      otherStyles={otherStyles}
      placeholderTextColor={getColor('NEUTRAL300')}
      hasDropDown={hasDropDown}
     />
    );
   }}
  />
 );
};

ControllerInput.defaultProps = {
 inputStyles: {},
 secureTextEntry: false,
 withIcon: true,
 keyboardType: 'default',
};

export default ControllerInput;
