import React from 'react';
import { Linking, ScrollView, StyleSheet, View } from 'react-native';

import SmallMap from 'components/map/SmallMap';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import ImageComponent from 'components/reusable/ImageComponent';
import Input from 'components/reusable/Input';
import SquircleContainer from 'components/reusable/SquircleContainer';

import ScreenContainer from 'reusable/ScreenContainer';
import Text from 'reusable/Text';

import { SCREEN_WIDTH, WINDOW_WIDTH } from 'utils/dimensions';
import { isAndroid } from 'utils/platform';
import { lineHeight } from 'utils/theme';

const GAP_WIDTH = 6;
const ITEM_WIDTH = WINDOW_WIDTH * 0.856;
const DUMMY_ITEM_WIDTH = (WINDOW_WIDTH - ITEM_WIDTH) / 2 - GAP_WIDTH;

const TreeInfo = ({ route }) => {
 const treeInfoData = route.params.data;
 const poligonsData = route.params.poligonsData;

 const openLink = async (url) => {
  if (url) {
   await Linking.openURL(url);
  }
 };

 return (
  <ScrollView style={{ flex: 1, marginBottom: 30 }}>
   <FocusAwareStatusBar
    barStyle={isAndroid ? 'dark-content' : 'light-content'}
   />

   <View style={{ flex: 1 }}>
    <ScreenContainer>
     <Text
      fontType='MontserratBold'
      fontSize={lineHeight.body1}
      style={[styles.desc, { marginTop: 16 }]}>
      Private
     </Text>

     <SmallMap
      coords={[
       treeInfoData.coordinates.longitude,
       treeInfoData.coordinates.latitude,
      ]}
      withPoligons={true}
      poligonsData={poligonsData}
     />

     <Text
      fontType='MontserratBold'
      fontSize={lineHeight.body1}
      style={[styles.desc, { marginTop: 40 }]}>
      Logo
     </Text>

     <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      style={{
       height: 120,
      }}>
      {treeInfoData.features[0].properties.avatar.map((img, index) => (
       <SquircleContainer
        key={index}
        cornerRadius={12}
        bgColor='#fff'
        style={{
         width: 100,
         height: 100,
         marginRight: 12,
        }}>
        <ImageComponent
         width={100}
         height={100}
         rectWidth={150}
         left={0}
         rectHeight={150}
         contentFit='cover'
         transition={100}
         source={{ uri: img }}
         style={{ flex: 1, borderRadius: 12 }}
        />
       </SquircleContainer>
      ))}
     </ScrollView>

     <Text
      fontType='MontserratBold'
      fontSize={lineHeight.body1}
      style={[styles.desc, { marginTop: 20 }]}>
      Main Info
     </Text>

     <Text
      fontType='MontserratBold'
      fontSize={lineHeight.body2}
      color='NEUTRAL600'
      style={[styles.desc]}>
      Name
     </Text>

     <Input
      withIcon={false}
      editable={false}
      placeholder='Enter name'
      value={treeInfoData.features[0].properties.geofenceName}
     />

     <Text
      fontType='MontserratBold'
      fontSize={lineHeight.body2}
      color='NEUTRAL600'
      style={[styles.desc, { marginTop: 40 }]}>
      Descrption
     </Text>

     <View style={{ height: 100 }}>
      <Input
       withIcon={false}
       multiline={true}
       placeholder='Enter description'
       value={treeInfoData.features[0].properties.info}
       containerHeight={100}
       squircleContainerStyle={{
        height: '100%',
        paddingVertical: 5,
       }}
      />
     </View>

     <Text
      fontType='MontserratBold'
      fontSize={lineHeight.body2}
      color='NEUTRAL600'
      style={[styles.desc, { marginTop: 40 }]}>
      URL link
     </Text>

     <Input
      onPressIn={() => openLink(treeInfoData.features[0].properties.website)}
      editable={false}
      withIcon={false}
      placeholder='Enter url link'
      value={treeInfoData.features[0].properties.website}
     />
    </ScreenContainer>
   </View>
  </ScrollView>
 );
};

export default TreeInfo;

TreeInfo.defaultProps = {
 isUnRegisteredUser: false,
 isOwner: false,
};

const styles = StyleSheet.create({
 imageContainer: {
  height: 310,
  width: ITEM_WIDTH,
  marginTop: 'auto',
  marginHorizontal: GAP_WIDTH,
 },
 dummyItem: {
  width: DUMMY_ITEM_WIDTH,
 },

 hrParent: {
  marginVertical: 16,
 },
 buttonStyle: {
  marginRight: 12,
  width: SCREEN_WIDTH - 112,
 },
 desc: {
  marginBottom: 16,
 },

 viewComponent: {},
});
