import Ajax from '../helpers/ajax';

import {
 PROFILE,
 UPDATENAME,
 EDITPASSWORD,
 EDITEMAIL,
 UPLOADAVATAR,
 PERMISSIONS,
 SAVEAVATAR,
} from 'utils/uris';

const getProfile = async (userId) => {
 try {
  return await Ajax.get(`${PROFILE}?userId=${userId}`);
 } catch (error) {
  return error;
 }
};

const changeUserName = async (data) => {
 try {
  return await Ajax.put(UPDATENAME, data);
 } catch (error) {
  return error;
 }
};

const changePassword = async (data) => {
 try {
  return await Ajax.put(EDITPASSWORD, data);
 } catch (error) {
  return error;
 }
};

const changeEmail = async (data) => {
 try {
  return await Ajax.put(EDITEMAIL, data);
 } catch (error) {
  return error;
 }
};

const uploadImage = async (blob, type) => {
 const fd = new FormData();

 fd.append('type', type);
 fd.append('image', blob);

 try {
  return await Ajax.post(UPLOADAVATAR, fd, true);
 } catch (error) {
  return error;
 }
};

const saveAvatar = async (data) => {
 try {
  return await Ajax.put(SAVEAVATAR, data);
 } catch (error) {
  return error;
 }
};

const getUserPermissions = async (userId) => {
 try {
  return await Ajax.get(`${PERMISSIONS}?userId=${userId}`);
 } catch (error) {
  return error;
 }
};

export {
 getProfile,
 changeUserName,
 changePassword,
 changeEmail,
 uploadImage,
 saveAvatar,
 getUserPermissions,
};
