import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { View, TextInput, StyleSheet } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import InputError from 'components/reusable/InputError';
import SquircleContainer from 'components/reusable/SquircleContainer';

import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';

const Input = (props) => {
 return <TextInput {...props} editable maxLength={1500} />;
};

const MultiLineTextInput = ({ placeholder, control, name, error }) => {
 const [multiLineHeight, setMultilineHeight] = useState(150);

 return (
  <Controller
   control={control}
   name={name}
   render={({ field: { onChange, onBlur, value } }) => {
    return (
     <>
      <SquircleContainer
       style={{ zIndex: -1 }}
       strokeWidth={1}
       strokeColor={error ? getColor('error') : getColor('NEUTRAL300')}
       bgColor={getColor('WHITE')}>
       <View style={{ flex: 1 }}>
        <Input
         value={value}
         multiline
         onChangeText={onChange}
         placeholder={placeholder}
         placeholderTextColor={getColor('NEUTRAL300')}
         onContentSizeChange={(event) => {
          setMultilineHeight(event.nativeEvent.contentSize.height);
         }}
         name
         style={[
          WebStyles.webInputTextStyle,
          {
           padding: 19,
           outlineStyle: 'none',
           height: multiLineHeight,
          },
         ]}
        />
       </View>
      </SquircleContainer>
      <InputError error={error} name={name} />
     </>
    );
   }}
  />
 );
};

const styles = StyleSheet.create({
 inputStyle: {
  fontFamily: fonts.MontserratBold,
 },
});

export default MultiLineTextInput;
