import { Image } from 'expo-image';
import usePermission from 'hooks/usePermission';
import jwt_decode from 'jwt-decode';
import React, { useRef } from 'react';
import {
 StyleSheet,
 View,
 FlatList,
 ScrollView,
 Alert,
 Linking,
 Pressable,
} from 'react-native';
import Animated, {
 useAnimatedScrollHandler,
 useSharedValue,
} from 'react-native-reanimated';
import { useSnapshot } from 'valtio';

import authStore from 'store/authStore';

import Paginator from 'screens/MobileNavigation/OnBoarding/Paginator';

import { deleteTree } from 'services/treeServices';

import AdditionalInfo from 'components/Treeinfo/AdditionalInfo';
import MoreInfoAboutTree from 'components/Treeinfo/MoreInfoAboutTree';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import ImageComponent from 'components/reusable/ImageComponent';
import SquircleContainer from 'components/reusable/SquircleContainer';

import Button from 'reusable/Button';
// import AddedBy from 'components/Treeinfo/AddedBy';
import DeleteComponent from 'reusable/DeleteComponent';
import Hr from 'reusable/Hr';
import ScreenContainer from 'reusable/ScreenContainer';
import Text from 'reusable/Text';

import { WINDOW_WIDTH, SCREEN_WIDTH } from 'utils/dimensions';
import { IMAGES } from 'utils/images';
import { storageSet, storageGetString, storageDel } from 'utils/mmkvStorage';
import { isAndroid } from 'utils/platform';
import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

const GAP_WIDTH = 6;
const ITEM_WIDTH = WINDOW_WIDTH * 0.856;
const ITEM_WIDTH_WITH_GAPS = ITEM_WIDTH + GAP_WIDTH * 2;
const DUMMY_ITEM_WIDTH = (WINDOW_WIDTH - ITEM_WIDTH) / 2 - GAP_WIDTH;
const FULL_ITEM_WIDTH = ITEM_WIDTH + DUMMY_ITEM_WIDTH + GAP_WIDTH;

const DATA = [
 {
  id: 1,
  mainTitle: '',
 },

 {
  id: 6,
  mainTitle: '',
 },
];

const DEFAULTDATA = [
 {
  id: 1,
  mainTitle: '',
 },
 {
  id: 2,
  isCustomImage: true,
  uri: IMAGES.tremapWebSigninImg,
  mainTitle: 'test',
 },

 {
  id: 6,
  mainTitle: '',
 },
];

const TreeInfo = ({ navigation, route, coordinates }) => {
 const store = useSnapshot(authStore);
 const token = storageGetString('AccessToken');

 const { permissions } = usePermission();
 const flatListRef = useRef();
 const currentIndex = useRef(0);
 const scrollOffset = useSharedValue(0);
 const treeInfoData = route.params.treeInfo || coordinates;
 const displayImages = route.params.displayImages;

 console.log(treeInfoData);
 const onScroll = useAnimatedScrollHandler((event) => {
  scrollOffset.value = Math.round(event.contentOffset.x);
 });

 const renderItem = ({ item }) => {
  const isDummy = item.mainTitle === '';

  if (isDummy) {
   return <View style={styles.dummyItem} />;
  } else {
   return (
    <SquircleContainer
     style={styles.imageContainer}
     bgColor='#fff'
     cornerRadius={16}>
     <ImageComponent
      contentFit='cover'
      width={ITEM_WIDTH}
      height={330}
      left={-10}
      rectWidth='155'
      rectHeight='141'
      transition={100}
      style={{ flex: 1, borderRadius: 16, width: '100%', height: '100%' }}
      source={item.isCustomImage ? item.uri : { uri: item.uri }}
     />
    </SquircleContainer>
   );
  }
 };

 const getItemLayout = (_, index) => ({
  length: FULL_ITEM_WIDTH,
  offset: FULL_ITEM_WIDTH * index,
  index,
 });

 const onDeleteTree = async () => {
  if (store.isOffline) {
   const OfflineEditedTrees = storageGetString('OfflineEditedTrees');

   if (OfflineEditedTrees) {
    const arr = JSON.parse(OfflineEditedTrees).filter(
     (tr) => tr.treeId !== treeInfoData._id
    );

    if (arr.length > 0) {
     storageSet('OfflineEditedTrees', JSON.stringify(arr));
    } else {
     storageDel('OfflineEditedTrees');
    }
   }

   const OfflineDeletTrees = storageGetString('OfflineDeletTrees');

   if (OfflineDeletTrees) {
    const d = JSON.stringify([...JSON.parse(OfflineDeletTrees), treeInfoData]);

    storageSet('OfflineDeletTrees', d);
   } else {
    storageSet('OfflineDeletTrees', JSON.stringify([treeInfoData]));
   }
  } else if (treeInfoData.properties.offlineTreeId) {
   const OfflineAddedTrees = storageGetString('OfflineAddedTrees');

   const filtr = JSON.parse(OfflineAddedTrees).filter(
    (w) =>
     w.properties.offlineTreeId &&
     w.properties.offlineTreeId !== treeInfoData.properties.offlineTreeId
   );

   if (filtr.length) {
    storageSet('OfflineAddedTrees', JSON.stringify(filtr));
   } else {
    storageDel('OfflineAddedTrees');
   }

   const filteredState = authStore.trees.filter(
    (w) =>
     treeInfoData.properties.offlineTreeId &&
     treeInfoData.properties.offlineTreeId !== w.properties.offlineTreeId
   );

   authStore.trees = filteredState;

   navigation.goBack();
  } else {
   const newArr = authStore.trees.filter((t) => {
    if (t._id !== treeInfoData._id) {
     return t;
    }
   });

   authStore.trees = newArr;

   storageSet('homeScreenMarkerPosition', JSON.stringify(false));

   navigation.navigate('HomeScreen');

   await deleteTree(treeInfoData._id);
  }
 };

 const onPressDelete = () => {
  Alert.alert(
   '',
   'This action will delete all info about this tree. Would you like to continue?',
   [
    {
     text: 'Cancel',
     style: 'cancel',
     onPress: () => console.log('Cancel Pressed'),
    },

    { text: 'Continue', onPress: () => onDeleteTree() },
   ]
  );
 };

 const updateTreeData = (data) => {
  if (data.imgUrls.length) {
   var reversedArr = [];
   for (let i = data.imgUrls.length - 1; i >= 0; i--) {
    reversedArr.push(data.imgUrls[i]);
   }
  }

  const treeInfo = {
   ...treeInfoData,
   imgUrls: data.imgUrls,
   private: data.private,
   properties: {
    family: data.properties.family,
    age: data.properties.age,
    biomass: data.properties.biomass,
    common: data.properties.common,
    crown: data.properties.crown,
    dbh: data.properties.dbh,
    genus: data.properties.genus,
    health: data.properties.health,
    height: data.properties.height,
    location: data.properties.location,
    planted: data.properties.planted,
    refId: data.properties.refId,
    resilience: data.properties.resilience,
    species: data.properties.species,
    structure: data.properties.structure,
    updated: data.properties.updated,
    variety: data.properties.variety,
    customField1: data.properties.customField1,
    customField2: data.properties.customField2,
    customField3: data.properties.customField3,
   },
  };

  if (data.properties.offlineTreeId) {
   treeInfo.properties.offlineTreeId = data.properties.offlineTreeId;
  }

  navigation.setParams({ treeInfo, displayImages: reversedArr || [] });
 };

 const onOpenOrganization = async () => {
  try {
   await Linking.openURL(treeInfoData.organizationImgUrl);
  } catch (error) {
   console.log(error);
  }
 };

 console.log(treeInfoData.organizationImg);
 return (
  <View style={{ flex: 1 }}>
   <FocusAwareStatusBar
    barStyle={isAndroid ? 'dark-content' : 'light-content'}
   />

   <ScrollView style={{ flex: 1 }}>
    <AnimatedFlatList
     data={
      treeInfoData.imgUrls && treeInfoData.imgUrls.length > 0
       ? [DATA[0], ...displayImages, DATA[1]]
       : DEFAULTDATA
     }
     horizontal
     ref={flatListRef}
     pagingEnabled
     onScroll={onScroll}
     renderItem={renderItem}
     decelerationRate='fast'
     disableIntervalMomentum
     scrollEventThrottle={16}
     getItemLayout={getItemLayout}
     showsHorizontalScrollIndicator={false}
     keyExtractor={(item) => item.id.toString()}
     contentContainerStyle={styles.contentContainerStyle}
     snapToOffsets={
      treeInfoData.imgUrls
       ? treeInfoData.imgUrls.map((_, index) => index * ITEM_WIDTH_WITH_GAPS)
       : DEFAULTDATA.map((_, index) => index * ITEM_WIDTH_WITH_GAPS)
     }
    />

    <View style={{ marginTop: 16 }}>
     <Paginator
      currentIndex={currentIndex}
      pageCount={4}
      pages={DATA}
      itemWidth={Math.round(ITEM_WIDTH_WITH_GAPS)}
      scrollOffset={scrollOffset}
      pointerEvents='none'
     />
    </View>

    <ScreenContainer>
     {treeInfoData.properties.common && (
      <Text fontType='MontserratBold' fontSize={lineHeight.h2}>
       {treeInfoData.properties.common}
      </Text>
     )}
     {treeInfoData.properties.genus && (
      <Text
       fontType='MontserratItalic'
       fontSize={lineHeight.subTitle3}
       style={styles.desc}>
       {treeInfoData.properties.genus}
      </Text>
     )}

     {treeInfoData.properties.species && (
      <Text
       fontType='MontserratItalic'
       fontSize={lineHeight.subTitle3}
       style={styles.desc}>
       {treeInfoData.properties.species}
      </Text>
     )}

     {store.isLoggedIn &&
      (treeInfoData.organizationId === jwt_decode(token).enterprise ||
       treeInfoData.userId === jwt_decode(token).userId) &&
      permissions !== 'Viewer' && (
       <View style={{ flexDirection: 'row', marginTop: 16 }}>
        <Button
         title='Edit'
         bgColor={getColor('NORMAL_GREEN')}
         customStyles={styles.buttonStyle}
         onPress={() =>
          navigation.navigate('EditTreeInfo', {
           treeInfoData,
           updateTreeData: (newData) => updateTreeData(newData),
           coordinates: route.params.coordinates,
          })
         }
        />

        <DeleteComponent onPress={onPressDelete} />
       </View>
      )}

     <View style={{ marginBottom: 20, marginTop: 24 }}>
      {treeInfoData.organizationImg && treeInfoData.organizationImg[0] && (
       <>
        <Text
         fontSize={lineHeight.body3}
         color='NEUTRAL500'
         style={{ marginBottom: 8 }}>
         Sponsored by
        </Text>

        <View style={[styles.container, { justifyContent: 'flex-start' }]}>
         <Pressable onPress={onOpenOrganization}>
          <Image
           source={{ uri: treeInfoData.organizationImg[0] }}
           style={{ width: 60, height: 60, borderRadius: 8 }}
          />
         </Pressable>
        </View>

        <View style={styles.hrParent}>
         <Hr />
        </View>
       </>
      )}

      {store.isLoggedIn ? (
       <>
        <Text
         fontSize={lineHeight.subTitle2}
         fontType='MontserratBold'
         style={{ marginBottom: 12, marginTop: 12 }}>
         Additional Info
        </Text>

        <AdditionalInfo
         treeInfoData={treeInfoData}
         properties={treeInfoData.properties}
        />
       </>
      ) : (
       <MoreInfoAboutTree text='Want more info about this tree?' />
      )}
     </View>
    </ScreenContainer>
   </ScrollView>
  </View>
 );
};

export default TreeInfo;

TreeInfo.defaultProps = {
 isUnRegisteredUser: false,
 isOwner: false,
};

const styles = StyleSheet.create({
 imageContainer: {
  height: 310,
  width: ITEM_WIDTH,
  marginTop: 'auto',
  marginHorizontal: GAP_WIDTH,
 },
 container: {
  flexDirection: 'row',
  paddingBottom: 10,
 },
 dummyItem: {
  width: DUMMY_ITEM_WIDTH,
 },
 desc: {
  marginTop: 4,
 },
 hrParent: {
  marginVertical: 6,
 },
 buttonStyle: {
  marginRight: 12,
  width: SCREEN_WIDTH - 112,
 },
});
