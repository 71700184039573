import debounce from 'lodash/debounce';
import React, { useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import authStore from 'store/authStore';

import { deleteUser } from 'services/authService';

import BottomSheetModal from 'components/bottomSheet/BottomSheetModal';
import Button from 'components/reusable/Button';
import Text from 'components/reusable/Text';

import { Defaults } from 'utils/defaults';
import { SCREEN_WIDTH } from 'utils/dimensions';
import { storageDel } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';

const DeleteAccount = ({ navigation }) => {
 const sheetRef = useRef(null);
 const [loading, setLoading] = useState(false);
 const onRemoveRef = debounce(Defaults.BottomSheetModal.hide, 200);
 const onNavigateFunc = debounce(() => navigation.push('Home'), 200);

 const logOut = () => {
  storageDel('AccessToken');
  storageDel('RefreshToken');
  storageDel('userId');
  authStore.profile = null;
  authStore.isLoggedIn = false;
  authStore.permissions = null;
 };

 const onClose = () => {
  sheetRef.current?.close();
  onRemoveRef();
 };

 const handleDeleteUser = async () => {
  setLoading(true);

  try {
   //  await deleteUser(authStore.profile.email);
   logOut();
   onClose();
   onNavigateFunc();
  } catch (error) {
   setLoading(false);
  }
 };

 return (
  <>
   <BottomSheetModal
    snapPoints={['50%', '50%']}
    sheetRef={sheetRef}
    onClose={() => Defaults.BottomSheetModal.hide()}>
    <View style={styles.contentContainer}>
     <Text style={{ marginTop: 24 }}>
      If you wish to close your account and don't wish your tree data to remain
      in the Tremap system, you must delete your data before you close your
      account. Whatever data you choose not to delete will remain in the Tremap
      database. If a given tree and its associated attributes and photos was
      marked as private, it will remain as private and not publicly visible to
      any other user. If it was marked as public then those trees will of course
      remain publicly visible.
     </Text>

     <Button
      title='Delete account'
      disabled={loading}
      onPress={handleDeleteUser}
      bgColor={getColor('NORMAL_GREEN')}
      customStyles={{ width: SCREEN_WIDTH - 48 }}
     />
    </View>
   </BottomSheetModal>
  </>
 );
};

export default DeleteAccount;

const styles = StyleSheet.create({
 contentContainer: {
  flex: 1,
  paddingHorizontal: 24,
  paddingBottom: 30,
  justifyContent: 'space-between',
  alignItems: 'center',
 },
});
