import MapboxGL, {
 Camera,
 CircleLayer,
 FillLayer,
 LineLayer,
 MapView,
 MarkerView,
 ShapeSource,
 SymbolLayer,
 UserLocation,
} from '@rnmapbox/maps';
import * as Location from 'expo-location';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import {
 Alert,
 AppState,
 Image,
 Pressable,
 StyleSheet,
 TouchableOpacity,
} from 'react-native';
import { useSnapshot } from 'valtio';

import MapAdditionalFeatures from './MapAdditionalFeatures';
import { clusterStyle, layerStyles, singlePointStyle } from './styles';

import authStore from 'store/authStore';

import PoligonsInfo from 'screens/MobileNavigation/TreeInfo/PoligonsInfo';
import TreeInfoSmall from 'screens/MobileNavigation/TreeInfo/TreeInfoSmall';

import { getClusters } from 'services';
import { getGeofences } from 'services/mapServices';

import Icon from 'components/reusable/Icon';
import SquircleContainer from 'components/reusable/SquircleContainer';

import { getCurrentPositionAsync, goToSettings } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { IMAGES } from 'utils/images';
import {
 storage,
 storageGetBoolean,
 storageGetString,
 storageSet,
} from 'utils/mmkvStorage';
import { isAndroid } from 'utils/platform';
import { MAP_ACCESSTOKEN } from 'utils/uris';

MapboxGL.setAccessToken(MAP_ACCESSTOKEN);

const mapStyle = {
 Satellite: 'mapbox://styles/mapbox/satellite-v9',
 SatelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v11',
};

const defaultSettings = {
 centerCoordinate: [0, 0],
 zoomLevel: 2,
};

const Map = ({ navigation, isOffline, networkSpeed }) => {
 const mapRef = useRef(null);
 const shapeSource = useRef();
 const cameraRef = useRef();
 const locationStatus = useRef('');
 const ignoringAppState = useRef(false);
 const appState = useRef(AppState.currentState);

 const store = useSnapshot(authStore);

 let geofence = storageGetBoolean('isCheckedGeofence');
 let streets = storageGetBoolean('isCheckStreets');

 const [appForeground, setAppForeground] = useState(
  appState.current === 'active'
 );
 const [poligonsData, setPoligonsData] = useState();
 const [mapLoaded, setMapLoaded] = useState(false);
 const [selectedPin, setSelectedPin] = useState('');
 const [download, setDownload] = useState('');
 const [offlineData, setOfflineData] = useState();
 const [location, setLocation] = useState();
 const [styleURL, setStyleURL] = useState(
  streets ? mapStyle.SatelliteStreet : mapStyle.Satellite
 );
 const [openAdditionalInfo, setOpenAdditionalInfo] = useState(false);
 const [isShowGeofence, setIsShowGeofence] = useState(geofence);

 const bboxData = useRef({ bounds: null, zoom: 2 });
 const getRegionChangeDebouncedFunc = debounce(
  (e) => onRegionDidChange(e),
  500
 );

 useEffect(() => {
  const subscription = AppState.addEventListener('change', (nextAppState) => {
   if (!ignoringAppState.current) {
    if (
     appState.current.match(/inactive|background/) &&
     nextAppState === 'active'
    ) {
     checkLocationPermission();
     console.log('App has come to the foreground!');
    }
   }

   appState.current = nextAppState;

   subscription.remove();
  });

  return () => subscription.remove();
 }, []);

 useEffect(() => {
  if (cameraRef.current && locationStatus.current === 'granted' && location) {
   cameraRef.current.setCamera({ centerCoordinate: location, zoomLevel: 18 });
  } else if (cameraRef.current) {
   cameraRef.current.setCamera(defaultSettings);
  }
 }, [location]);

 useEffect(() => {
  if (appForeground) checkLocationPermission();
 }, [appForeground]);

 const checkLocationPermission = async () => {
  ignoringAppState.current = true;

  const { status } = await Location.requestForegroundPermissionsAsync();

  locationStatus.current = status;
  ignoringAppState.current = false;

  if (status !== 'granted') {
   goToSettings();
  } else {
   getCurrentPositionAsync().then((res) => {
    setLocation([res?.coords?.longitude || 0, res?.coords?.latitude || 0]);
   });
  }
 };

 useEffect(() => {
  if (authStore.isSearchMode) {
   if (selectedPin) {
    setSelectedPin('');
    Defaults.toast.hide();
   }
  }
 }, [authStore.isSearchMode]);

 useEffect(() => {
  if (isOffline) {
   getPacks();
  }
 }, [isOffline]);

 useEffect(() => {
  if (offlineData) {
   authStore.trees = offlineData.clusters;
  }
 }, [offlineData]);

 const getPoligonsData = async () => {
  const res = await getGeofences();

  if (poligonsData) {
   const isEqual = _.isEqual(res.features, poligonsData.features);

   if (!isEqual) {
    setPoligonsData(res);
   }
  } else if (!poligonsData) {
   setPoligonsData(res);
  }
 };

 const progressListener = (offlineRegion, status) => {
  console.log(status.percentage);

  setDownload(status.percentage);

  if (status.percentage === 100) {
   mapRef.current.getCenter().then((res) => {
    storage.set('coordinates', JSON.stringify(res));

    const newClustersArr = authStore.trees.map((cl) => {
     return {
      ...cl,
      properties: {
       ...cl.properties,
       _id: cl._id,
       userId: cl.userId,
       custom: cl.custom,
       private: cl.private,
       createdAt: cl.createdAt,
       imgUrls: cl.imgUrls,
      },
     };
    });

    storage.set('clustersData', JSON.stringify(newClustersArr));

    setDownload('');
    Alert.alert('Download Complete');
   });
  }
 };

 const locationIsNotEqualDefault = (locationVal) => {
  if (locationVal && locationVal[0] !== 0 && locationVal[1] !== 0) {
   return true;
  }

  return false;
 };

 const errorListener = (offlineRegion, err) => {
  console.log('errrrrr...', err);
  Alert.alert('Area too large');
 };

 const storePacks = async () => {
  console.log('Start Downloading');

  const { bounds } = bboxData.current;

  await MapboxGL.offlineManager
   .createPack(
    {
     name: 'offlinePack',
     styleURL: 'mapbox://styles/mapbox/satellite-streets-v11',
     minZoom: 14,
     maxZoom: 20,
     bounds,
    },
    progressListener,
    errorListener
   )
   .catch((err) => {
    Alert.alert(
     'Offline map was already downloaded.',
     'Do you want to overwrite it?',
     [
      {
       text: 'Cancel',
       onPress: () => Alert.alert('Cancel Pressed'),
       style: 'cancel',
      },
      {
       text: 'Yes',
       onPress: () => deletePack(),
       style: 'Yes',
      },
     ]
    );
   });
 };

 const getPacks = async () => {
  await MapboxGL.offlineManager.getPack('offlinePack');

  const coords = storage.getString('coordinates');
  const clustersArr = storage.getString('clustersData');

  const data = {
   long: JSON.parse(coords)[1],
   lat: JSON.parse(coords)[0],
   clusters: JSON.parse(clustersArr),
  };

  setOfflineData(data);
 };

 const deletePack = async () => {
  console.log('Delete Pack');
  await MapboxGL.offlineManager.deletePack('offlinePack');
 };

 const getCurrentLocation = async () => {
  cameraRef.current?.setCamera({
   centerCoordinate: [location[0], location[1]],
   zoomLevel: 16,
   animationDuration: 500,
   animationMode: 'moveTo',
  });
 };

 const getClustersData = async () => {
  if (!isOffline) {
   const { bounds, zoom } = bboxData.current;

   if (bounds) {
    const { data } = await getClusters(
     `?zoom=${Math.round(zoom)}&westLng=${bounds[0][0]}&southLat=${
      bounds[1][1]
     }&eastLng=${bounds[1][0]}&northLat=${bounds[0][1]}&private=null${
      Defaults.userId ? `&userId=${Defaults.userId}` : ''
     }`
    );

    const OfflineAddedTrees = storageGetString('OfflineAddedTrees');
    const offlineTrees = OfflineAddedTrees ? JSON.parse(OfflineAddedTrees) : [];

    if (
     (data.clusters && data.clusters?.length > 0) ||
     offlineTrees.length > 0
    ) {
     const newAr = [...data.clusters, ...offlineTrees].map((c) => {
      if (c.geometry.type === 'Point') {
       if (!c.properties) {
        return { ...c, properties: { color: getColorForTree(c) } };
       } else {
        return {
         ...c,
         properties: {
          ...c.properties,
          DatePlanted: '',
          Notes: '',
          InfrastructureObject: '',
          color: getColorForTree(c),
         },
        };
       }
      }
     });

     authStore.trees = newAr;
    } else if (
     data.clusters &&
     data.clusters?.length === 0 &&
     store.trees.length > 0
    ) {
     authStore.trees = [];
    }
   }
  }
 };

 const getClustersDataWithBounds = async (e) => {
  const bounds = [e.properties.bounds.ne, e.properties.bounds.sw];
  const zoom = Math.floor(e.properties.zoom);

  bboxData.current = { bounds, zoom };

  getClustersData();
 };

 const onPressShapeSource = async (point) => {
  Defaults.toast.hide();

  if (point.features[0].properties.cluster) {
   console.log('press on cluster');
  } else {
   let myPoint = point;

   const coordinates =
    myPoint.features[0].properties.coordinates ||
    myPoint.features[0].geometry.coordinates;

   const properties = point.features[0];

   if (isOffline || networkSpeed > Defaults.maxWaitTime) {
    myPoint = {
     _id: point.features[0].properties._id,
     userId: point.features[0].properties.userId,
     custom: point.features[0].properties.custom,
     geometry: {
      coordinates: [coordinates[0], coordinates[1]],
     },
     imgUrls: point.features[0].properties.imgUrls,
     private: point.features[0].properties.private,
     properties: properties.properties,
    };
   }

   if (openAdditionalInfo) {
    setOpenAdditionalInfo(false);
   }

   setSelectedPin(coordinates);

   storageSet('homeScreenMarkerPosition', JSON.stringify(true));

   const func = debounce(
    () =>
     Defaults.toast.show(
      <TreeInfoSmall
       data={myPoint}
       coordinates={coordinates}
       isOffline={isOffline}
       onClearPin={() => setSelectedPin('')}
       navigation={navigation}
      />
     ),
    100
   );

   func();
  }
 };

 const onRegionDidChange = async (e) => {
  if (!isOffline) {
   if (!authStore.isSearchMode) {
    getClustersDataWithBounds(e);
    storage.set('centerCoordinates', JSON.stringify(e.properties.center));
    getPoligonsData();
   }
  }
 };

 const togglePoligons = () => {
  setIsShowGeofence((prev) => !prev);
 };

 const toggleMap = () => {
  if (storageGetBoolean('isCheckStreets')) {
   setStyleURL(mapStyle.Satellite);
  } else {
   setStyleURL(mapStyle.SatelliteStreet);
  }
 };

 const getColorForTree = (tree) => {
  if (tree.custom === true && authStore.profile)
   if (tree.private === true) {
    return '#717993';
   } else if (
    tree.organizationId === authStore.profile?.enterprise ||
    tree.userId === authStore.profile._id
   ) {
    return '#559158';
   }
  return '#ed7d32';
 };

 if (offlineData && isOffline) {
  return (
   <MapboxGL.MapView
    scrollEnabled={false}
    pitchEnabled={false}
    rotateEnabled={false}
    zoomEnabled={false}
    style={styles.container}
    ref={mapRef}
    styleURL={
     streets && JSON.parse(streets)
      ? mapStyle.SatelliteStreet
      : mapStyle.Satellite
    }
    attributionEnabled={false}
    cluster={false}
    compassEnabled
    logoPosition={{ bottom: isAndroid ? 0 : -30, right: 20 }}
    compassPosition={{ left: 8, top: 38 }}>
    <MapboxGL.Camera
     minZoom={14}
     ref={(ref) => {
      if (ref) {
       ref?.moveTo([offlineData.lat, offlineData.long]);
      }
     }}
     defaultSettings={{ zoomLevel: 16 }}
    />

    <MapboxGL.UserLocation showsUserHeadingIndicator minDisplacement={1000} />

    <MapboxGL.ShapeSource
     ref={shapeSource}
     shape={{ type: 'FeatureCollection', features: store.trees }}
     id='symbolLayerSource'
     hitbox={{ width: 18, height: 18 }}
     clusterRadius={50}
     maxZoomLevel={6}
     onPress={(point) => onPressShapeSource(point)}
     cluster={false}>
     <MapboxGL.SymbolLayer id='pointCount' style={layerStyles.clusterCount} />

     <MapboxGL.CircleLayer
      id='clusteredPoints'
      belowLayerID='pointCount'
      filter={['has', 'point_count']}
      style={clusterStyle}
     />

     <MapboxGL.CircleLayer
      id='singlePoint'
      source='point'
      filter={['!', ['has', 'point_count']]}
      style={singlePointStyle}
     />
    </MapboxGL.ShapeSource>
   </MapboxGL.MapView>
  );
 } else {
  return (
   <>
    {locationIsNotEqualDefault(location) && (
     <SquircleContainer
      style={[styles.buttonsStyle, { top: isOffline ? 75 : 139 }]}
      bgColor='#fff'
      cornerRadius={12}>
      <TouchableOpacity
       onPress={() => {
        setOpenAdditionalInfo((prev) => !prev);
        Defaults.toast.hide();
       }}>
       <Icon name='more' style={{ fontSize: 22 }} />
      </TouchableOpacity>
     </SquircleContainer>
    )}

    <MapView
     pitchEnabled={false}
     rotateEnabled={false}
     style={{ opacity: mapLoaded ? 1 : 0, flex: 1 }}
     ref={mapRef}
     styleURL={styleURL}
     logoPosition={{ bottom: isAndroid ? 0 : -30, right: 20 }}
     attributionEnabled={false}
     compassEnabled
     regionDidChangeDebounceTime={0}
     compassPosition={{ left: 8, top: 78 }}
     onCameraChanged={getRegionChangeDebouncedFunc}
     onDidFinishLoadingMap={() => setMapLoaded(true)}>
     <Camera
      ref={cameraRef}
      centerCoordinate={location}
      zoomLevel={locationIsNotEqualDefault(location) ? 17 : 2}
      animationMode='moveTo'
      animationDuration={1000}
     />

     <UserLocation
      visible={locationIsNotEqualDefault(location) ? true : false}
      minDisplacement={100}
     />

     {location && mapLoaded && (
      <>
       <ShapeSource
        ref={shapeSource}
        shape={{ type: 'FeatureCollection', features: store.trees }}
        id='symbolLocationSource'
        hitbox={{ width: 38, height: 38 }}
        clusterRadius={50}
        onPress={(point) => onPressShapeSource(point)}
        cluster={false}>
        <SymbolLayer
         id='pointCount'
         filter={['has', 'point_count_abbreviated']}
         style={layerStyles.clusterCount}
        />

        <CircleLayer
         id='clusteredPoints'
         belowLayerID='pointCount'
         filter={['has', 'point_count_abbreviated']}
         style={clusterStyle}
        />

        <CircleLayer
         id='singlePoint'
         source='point'
         filter={['!', ['has', 'point_count_abbreviated']]}
         style={singlePointStyle}
        />
       </ShapeSource>

       {selectedPin &&
        JSON.parse(storageGetString('homeScreenMarkerPosition')) === true && (
         <MarkerView
          coordinate={selectedPin}
          isSelected={selectedPin ? true : false}>
          <Pressable
           onPress={() => {
            setSelectedPin(false);
            Defaults.toast.hide();
           }}
           style={{ marginBottom: 52, marginRight: 25 }}>
           <Image source={IMAGES.treepoint} style={styles.treePoint} />
          </Pressable>
         </MarkerView>
        )}

       {/* Polygons */}
       {poligonsData && isShowGeofence && (
        <ShapeSource
         id='source'
         shape={poligonsData}
         onPress={(e) => {
          if (Defaults.toast) {
           Defaults.toast.hide();
          }
          const func = debounce(
           () =>
            Defaults.toast.show(
             <PoligonsInfo
              data={e}
              poligonsData={poligonsData}
              isOffline={isOffline}
              coordinates={e.coordinates}
              navigation={navigation}
             />
            ),
           100
          );
          func();
         }}>
         <LineLayer
          id='line'
          layerIndex={6}
          style={{ lineColor: '#D96C31', lineWidth: 3 }}
         />
         <FillLayer
          id='fill'
          layerIndex={5}
          style={{ fillColor: '#D96C313D' }}
         />
        </ShapeSource>
       )}
       {/* Polygons */}
      </>
     )}
    </MapView>

    {locationIsNotEqualDefault(location) && (
     <TouchableOpacity
      onPress={getCurrentLocation}
      style={[
       styles.currentLocationContainer,
       { bottom: openAdditionalInfo ? 330 : 110 },
      ]}>
      <Image style={styles.imageStyle} source={IMAGES.currentLocation} />
      <Icon name='gps' style={styles.currentIcon} />
     </TouchableOpacity>
    )}

    {openAdditionalInfo && (
     <MapAdditionalFeatures
      onClose={() => setOpenAdditionalInfo(false)}
      navigation={navigation}
      isOffline={isOffline}
      toggleMap={toggleMap}
      download={download}
      storePacks={storePacks}
      togglePoligons={togglePoligons}
     />
    )}
   </>
  );
 }
};

export default Map;

const styles = StyleSheet.create({
 container: {
  flex: 1,
 },

 buttonsStyle: {
  width: 48,
  height: 48,
  right: 20,
  top: 75,
  zIndex: 11,
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'center',
 },
 currentLocationContainer: {
  right: 20,
  width: 48,
  height: 48,
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
 },
 currentIcon: {
  color: '#fff',
  fontSize: 20,
 },
 imageStyle: {
  width: 48,
  height: 48,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  position: 'absolute',
 },
 treePoint: {
  width: 46,
  height: 51,
 },
});
