import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
 interpolate,
 useAnimatedStyle,
 useSharedValue,
 withTiming,
} from 'react-native-reanimated';
import { useSnapshot } from 'valtio';

import data from '../../../data.json';

import authStore from 'store/authStore';

import { getProfile } from 'services/userService';

import { getNetworkStatus, getPermissions } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { IMAGES } from 'utils/images';
import {
 storageGetBoolean,
 storageGetString,
 storageSet,
} from 'utils/mmkvStorage';

function AnimatedSplashScreen() {
 const position = useSharedValue(0);
 const [isSplashReady, setSplashReady] = useState(false);

 const getAccessToken = storageGetString('AccessToken');
 const isOnboardingCompleted = storageGetBoolean('isOnboardingCompleted');
 const userId = storageGetString('userId');

 const getProfileData = async () => {
  const profile = await getProfile(storageGetString('userId'));
  authStore.profile = profile.user;

  setSplashReady(true);
 };

 const authorizedUser = async () => {
  const status = await getNetworkStatus();
  authStore.isOffline = status;

  if (!status) {
   getPermissions(userId);
   getProfileData();

   authStore.isLoggedIn = true;
   Defaults.userId = userId;
  } else {
   getDecodedUser();
  }
 };

 const unAuthorizedUser = async () => {
  const status = await getNetworkStatus();

  authStore.isOffline = status;
  setSplashReady(true);
 };

 const getDecodedUser = () => {
  authStore.profile = jwt_decode(getAccessToken);
  storageSet('AccessToken', getAccessToken);
  setSplashReady(true);
 };

 useEffect(() => {
  position.value = withTiming(1, { duration: 800 });

  if (isOnboardingCompleted) {
   authStore.isOnboardingCompleted = true;
  }

  if (
   !storageGetString('genusData') ||
   JSON.parse(storageGetString('genusData'))[0].genus !== 'Other'
  ) {
   storageSet('genusData', JSON.stringify(data));
  }

  if (storageGetBoolean('isCheckStreets') === undefined) {
   storageSet('isCheckStreets', true);
  }

  if (storageGetBoolean('isCheckedGeofence') === undefined) {
   storageSet('isCheckedGeofence', true);
  }

  if (getAccessToken) {
   authorizedUser();
  } else {
   unAuthorizedUser();
  }
 }, []);

 useEffect(() => {
  if (isSplashReady) {
   setTimeout(() => {
    authStore.isAppReady = true;
   }, 1000);
  }
 }, [isSplashReady]);

 const animatedStyles = useAnimatedStyle(() => {
  return {
   opacity: interpolate(position.value, [0, 1], [0, 1]),
   transform: [{ scale: interpolate(position.value, [0, 1], [0, 1]) }],
  };
 });

 return (
  <View pointerEvents='none' style={styles.containerStyle}>
   <Animated.Image
    style={[styles.imageStyle, animatedStyles]}
    source={IMAGES.SplashImage}
   />
  </View>
 );
}

export default AnimatedSplashScreen;

const styles = StyleSheet.create({
 containerStyle: {
  ...StyleSheet.absoluteFill,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
 },
 imageStyle: {
  width: '80%',
  height: '80%',
  resizeMode: 'contain',
 },
});
