import BottomSheet, { BottomSheetView } from '@gorhom/bottom-sheet';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
 ActivityIndicator,
 Alert,
 ScrollView,
 StyleSheet,
 View,
} from 'react-native';

import SearchParent from './SearchParent';

import authStore from 'store/authStore';

import { filterSearchedTrees, searchTree } from 'services/treeServices';

import Button from 'components/reusable/Button';
import KeyboardAwareScrollViewComponent from 'components/reusable/KeyboardAwareScrollViewComponent';
import KeyboardPaddingView from 'components/reusable/KeyboardPaddingView';
import Text from 'components/reusable/Text';

import { Defaults } from 'utils/defaults';
import { SCREEN_WIDTH } from 'utils/dimensions';
import { storageGetString } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';

const SearchScreen = () => {
 const sheetRef = useRef(null);
 const coords = storageGetString('centerCoordinates');

 const [trees, setTrees] = useState([]);
 const [barge, setBarge] = useState([]);
 const [error, setError] = useState(false);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
  handler('', JSON.parse(coords));
 }, []);

 const onCloseFunc = debounce(Defaults.BottomSheetModal.hide, 500);

 const handler = useCallback(
  debounce((value) => getSearchData(value), 300),
  []
 );

 const getSearchData = async (value) => {
  const response = await searchTree(value, JSON.parse(coords));

  if (response.trees.length > 0) {
   const filteredData = _.filter(response.trees, function (o) {
    return !o.email;
   });

   setTrees(filteredData);
  } else {
   setTrees([]);
  }

  setLoading(false);
 };

 const clearState = () => {
  setTrees([]);
  setBarge([]);
  authStore.isSearchMode = false;
  sheetRef.current.close();
 };

 const onClose = () => {
  sheetRef.current?.close();
  onCloseFunc();
 };

 const updateBarges = (item) => {
  if (barge.length > 0 && barge.find((w) => w._id === item._id)) {
   Alert.alert('This tree has already been added');
  } else {
   if (barge.length < 3) {
    setBarge([...barge, item]);
   } else {
    if (error !== true) {
     setError(true);
    }
   }
  }
 };

 const onDeleteBarge = (item) => {
  const filteredTrees = barge.filter((w) => w._id !== item._id);
  setBarge(filteredTrees);
 };

 const searchTrees = async () => {
  const data = {
   organizationIds: [],
   names: [],
   genuses: [],
   species: [],
   userIds: [],
   userId: authStore.profile._id,
  };

  const arr = [];

  barge.map((item) => {
   if (item?.properties?.common) {
    arr.push({ name: item.properties.common });
   } else if (item?.properties?.genus) {
    arr.push({ genus: item.properties.genus });
   } else if (item?.enterpriseName) {
    arr.push({ company: item._id });
   } else if (item?.email) {
    arr.push({ userId: item._id });
   } else if (item?.properties?.species) {
    arr.push({ species: item?.properties?.species });
   }
  });

  for (const item of arr) {
   if (item.company !== undefined) {
    console.log(item.company);
    data.organizationIds.push(item.company);
   } else if (item.name !== undefined) {
    data.names.push(item.name);
   } else if (item.genus !== undefined) {
    data.genuses.push(item.genus);
   } else if (item.species !== undefined) {
    data.species.push(item.species);
   } else if (item.userIds !== undefined) {
    data.userIds.push(item.userIds);
   }
  }

  const {
   organizationIds,
   names,
   genuses,
   userIds,
   species,
   userId,
   radius = 10,
  } = data;
  const res = await filterSearchedTrees(
   organizationIds,
   names,
   genuses,
   userIds,
   species,
   userId,
   JSON.parse(coords)[0],
   JSON.parse(coords)[1],
   radius
  );

  if (res.message === 'Success') {
   if (res.trees && res.trees?.length > 0) {
    const newAr = res.trees.map((c) => {
     if (c.geometry.type === 'Point') {
      if (!c.properties) {
       return { ...c, properties: { color: getColorForTree(c) } };
      } else {
       return {
        ...c,
        properties: {
         ...c.properties,
         color: getColorForTree(c),
        },
       };
      }
     }
    });

    authStore.isSearchMode = true;
    authStore.trees = newAr;
   }

   sheetRef.current.close();
  }
 };

 const getColorForTree = (tree) => {
  if (tree.custom === true && authStore.profile)
   if (tree.private === true) {
    return '#717993';
   } else if (
    tree.organizationId === authStore.profile?.enterprise ||
    tree.userId === authStore.profile._id
   ) {
    return '#559158';
   }
  return '#ed7d32';
 };

 return (
  <BottomSheet
   index={1}
   ref={sheetRef}
   snapPoints={['50%', '100%']}
   overDragResistanceFactor={5}
   enableOverDrag={false}
   enablePanDownToClose
   onClose={onClose}>
   <BottomSheetView style={styles.contentContainer}>
    <KeyboardAwareScrollViewComponent>
     <View
      style={{
       flex: 1,
       paddingHorizontal: 24,
      }}>
      <View>
       <SearchParent
        barges={barge}
        error={error}
        getSearchData={(e) => handler(e)}
        onDeleteBarge={(barge) => onDeleteBarge(barge)}
        onClose={() => onClose()}
       />
      </View>
      <View style={styles.hr} />

      <View style={{ marginBottom: 100 }}>
       <Text
        fontSize={12}
        fontType='MontserratSemiBold'
        color='NEUTRAL500'
        style={{ marginTop: 16, marginBottom: 20 }}>
        Nearby trees
       </Text>
       {loading ? (
        <ActivityIndicator size='large' color={getColor('NORMAL_GREEN')} />
       ) : (
        <ScrollView
         style={{ marginBottom: 200 }}
         showsVerticalScrollIndicator={false}>
         {trees.map((w) => {
          if (w) {
           return (
            <Text
             key={w._id}
             fontSize={12}
             fontType='MontserratSemiBold'
             color='NEUTRAL900'
             style={styles.TextStyle}
             onPress={() => updateBarges(w)}>
             <Text fontSize={14} color='NEUTRAL600'>
              {w?.properties
               ? 'Tree: '
               : w?.enterpriseName
               ? 'Company: '
               : null}
             </Text>
             {w?.properties?.common || w.enterpriseName}
            </Text>
           );
          }
         })}
        </ScrollView>
       )}
      </View>

      <View style={styles.buttonsParent}>
       <Button
        title='Clear all'
        onPress={clearState}
        disabledColor={getColor('LIGHT_GREEN')}
        disabled={authStore.isSearchMode ? false : true}
        bgColor={getColor('LIGHT_GREEN')}
        textColor={getColor('NORMAL_GREEN_HOVER')}
        customStyles={{ width: SCREEN_WIDTH / 2 - 24 - 7 }}
       />

       <Button
        title='Search'
        onPress={searchTrees}
        disabled={barge.length > 0 ? false : true}
        bgColor={getColor('NORMAL_GREEN')}
        customStyles={{
         marginLeft: 15,
         width: SCREEN_WIDTH / 2 - 24 - 7,
        }}
       />
      </View>
     </View>

     <KeyboardPaddingView />
    </KeyboardAwareScrollViewComponent>
   </BottomSheetView>
  </BottomSheet>
 );
};

export default SearchScreen;

const styles = StyleSheet.create({
 hr: {
  height: 4,
  marginTop: 20,
  backgroundColor: '#F4F5F8',
 },
 TextStyle: {
  marginBottom: 16,
  lineHeight: 20,
 },
 contentContainer: {
  flex: 1,
 },
 buttonsParent: {
  flexDirection: 'row',
  marginTop: 'auto',
  marginBottom: 30,
 },
});
