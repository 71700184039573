import { Image } from 'expo-image';
import React, { useEffect, useRef, useState } from 'react';
import { Linking, Pressable, StyleSheet, View } from 'react-native';
import Animated, {
 interpolate,
 useAnimatedStyle,
 useSharedValue,
 withTiming,
} from 'react-native-reanimated';

import { getTreeInfo } from 'services/treeServices';

import Icon from 'components/reusable/Icon';
import ImageComponent from 'components/reusable/ImageComponent';
import SquircleContainer from 'components/reusable/SquircleContainer';
import Text from 'components/reusable/Text';

import { Defaults } from 'utils/defaults';
import { SCREEN_WIDTH } from 'utils/dimensions';
import { IMAGES } from 'utils/images';
import { storageGetString, storageSet } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';

const AnimatedTouchable = Animated.createAnimatedComponent(Pressable);

const TreeInfoSmall = ({
 data,
 onClearPin,
 coordinates,
 isOffline,
 navigation,
}) => {
 const [treeInfo, setTreeInfo] = useState();
 const position = useSharedValue(0);
 const reversedImgsRef = useRef([]);

 function reverseArray(arr) {
  let reversedArr = [];
  for (let i = arr.length - 1; i >= 0; i--) {
   reversedArr.push(arr[i]);
  }
  return reversedArr;
 }

 const getTreeInfoData = async () => {
  if (!isOffline) {
   const response = await getTreeInfo(coordinates[0], coordinates[1]);

   if (response.tree.imgUrls) {
    let reversedArr = reverseArray(response.tree.imgUrls);
    reversedImgsRef.current = reversedArr;
   }

   setTreeInfo(response.tree);
  } else {
   setTreeInfo(data);
  }
 };

 useEffect(() => {
  position.value = withTiming(1, { duration: 500 });

  if (data?.properties?.offlineTreeId) {
   console.log(data);
   let offlineTreeinfo = {
    _id: '',
    createdAt: '2023-04-26T17:36:41.168Z',
    custom: true,
    geometry: {
     coordinates: data.geometry.coordinates,
     type: 'Point',
    },
    imgUrls: data.imgUrls,
    private: false,
    properties: {
     ...data.properties,
     coordinates: data.geometry.coordinates,
    },
    type: 'Feature',
    userId: storageGetString('userId'),
   };

   setTreeInfo(offlineTreeinfo);
  } else {
   getTreeInfoData();
  }

  navigation.addListener('beforeRemove', () => {
   if (Defaults.toast) {
    Defaults.toast.hide();
   }
  });
 }, [data]);

 const openTreeinfoModal = () => {
  if (treeInfo) {
   Defaults.toast.hide();
   onClearPin('');
   storageSet('homeScreenMarkerPosition', JSON.stringify(false));
   navigation.navigate('TreeInfo', {
    treeInfo,
    displayImages: reversedImgsRef.current,
    coordinates: [coordinates[0], coordinates[1]],
   });
  }
 };

 const animatedStyles = useAnimatedStyle(() => {
  return {
   opacity: interpolate(position.value, [0, 1], [0, 1]),
   bottom: interpolate(position.value, [0, 1], [80, 110]),
  };
 });

 const onOpenOrganization = async () => {
  try {
   await Linking.openURL(treeInfo.organizationImgUrl);
  } catch (error) {
   console.log(error);
  }
 };

 console.log(treeInfo);

 return (
  <AnimatedTouchable
   style={[styles.containerStyle, animatedStyles]}
   activeOpacity={1}
   onPress={openTreeinfoModal}>
   <SquircleContainer
    cornerRadius={16}
    bgColor='#fff'
    style={styles.dropdownContainer}>
    {treeInfo && (
     <>
      {treeInfo?.imgUrls?.length > 0 ? (
       <ImageComponent
        left={8}
        contentFit='cover'
        transition={100}
        width={134}
        height={147}
        rectWidth='130'
        rectHeight='143'
        source={treeInfo.imgUrls[treeInfo.imgUrls.length - 1].uri}
        style={styles.imageStyle}
       />
      ) : (
       <Image source={IMAGES.tremapWebSigninImg} style={styles.imageStyle} />
      )}

      <View
       style={{
        justifyContent: 'space-between',
        paddingTop: 16,
        paddingRight: 10,
        flex: 1,
       }}>
       <View>
        <Text
         fontSize={14}
         fontType='MontserratBold'
         style={{ paddingRight: 24 }}>
         {treeInfo?.properties?.common}
        </Text>
        <Text fontSize={14} fontType='MontserratItalic'>
         {treeInfo.properties.genus}
        </Text>
        <Text fontSize={12} fontType='MontserratItalic'>
         {treeInfo.properties.species}
        </Text>
       </View>

       {treeInfo.organizationImg && treeInfo.organizationImg[0] && (
        <View style={[styles.container, { justifyContent: 'flex-end' }]}>
         <Pressable onPress={onOpenOrganization}>
          <Image
           source={{ uri: treeInfo.organizationImg[0] }}
           style={{ width: 40, height: 40, borderRadius: 8 }}
          />
         </Pressable>
        </View>
       )}
      </View>
      <Pressable
       style={{ position: 'absolute', top: 16, right: 20 }}
       onPress={() => {
        Defaults.toast.hide();
        onClearPin('');
        storageSet('homeScreenMarkerPosition', JSON.stringify(false));
       }}>
       <Icon name='plus2' style={styles.closeIcon} />
      </Pressable>
     </>
    )}
   </SquircleContainer>
  </AnimatedTouchable>
 );
};

export default TreeInfoSmall;

const styles = StyleSheet.create({
 containerStyle: {
  position: 'absolute',
  left: 24,
  right: 24,
  height: 142,
  zIndex: 1000,
 },
 container: {
  flexDirection: 'row',
  paddingBottom: 10,
 },
 dropdownContainer: {
  flexDirection: 'row',
  height: 142,
  width: SCREEN_WIDTH - 48,
 },
 imageStyle: {
  width: 116,
  height: 126,
  marginRight: 10,
  borderRadius: 16,
  marginTop: 8,
  marginLeft: 8,
 },
 desc: {
  marginTop: 4,
 },
 closeIcon: {
  color: getColor('NEUTRAL700'),
  transform: [{ rotate: '45deg' }],
  fontSize: 20,
 },
});
