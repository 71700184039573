import useForm from 'hooks/useForm';
import jwt_decode from 'jwt-decode';
import React, { useState, useEffect, useRef } from 'react';
import { Text, View, StyleSheet, TextInput } from 'react-native';

import ModalWrapper from './ModalWrapper';

import authStore from 'store/authStore';

import { styles } from 'screens/MobileNavigation/SignInScreen/styles';
import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { registerNewUserInEnterprise } from 'services/authService';
import { verifyUser, getUserId } from 'services/index';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import ConfirmationCodeField from 'components/reusable/ConfirmationCodeField';
import KeyboardAwareScrollViewComponent from 'components/reusable/KeyboardAwareScrollViewComponent';
import KeyboardPaddingView from 'components/reusable/KeyboardPaddingView';
import { VerifySchema } from 'components/validations/schema';

import Button from 'reusable/Button';
import ScreenContainer from 'reusable/ScreenContainer';

import { Defaults } from 'utils/defaults';
import { storageSet } from 'utils/mmkvStorage';
import { isWeb } from 'utils/platform';
import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const VerifyModal = ({
 route,
 tokenForWeb,
 refreshTokenForWeb,
 userEmailForWeb,
 navigation,
 action,
}) => {
 const [userId, setuserId] = useState();

 const inputRef = useRef();

 const userEmail = isWeb ? userEmailForWeb : route.params?.newEmail;
 const tokenForMobile = route?.params?.tokenForMobile;
 const refreshTokenForMobile = route?.params?.refreshTokenForMobile;

 const token = isWeb ? tokenForWeb : tokenForMobile;
 const refreshToken = isWeb ? refreshTokenForWeb : refreshTokenForMobile;

 const defaultValues = {
  code: '',
 };

 const { handleSubmit, errors, isSubmitting, setValue, setError } = useForm(
  VerifySchema,
  defaultValues
 );

 const getUserid = async () => {
  const { id } = await getUserId(userEmail);
  setuserId(id);
 };

 useEffect(() => {
  getUserid();
 }, []);

 const onSubmit = async (values) => {
  const response = await verifyUser({
   otp: values.code,
   userId,
  });

  if (response.type === 'success') {
   storageSet('AccessToken', token);
   storageSet('RefreshToken', refreshToken);
   if (isWeb) {
    authStore.isLoggedIn = true;
    storageSet('userId', jwt_decode(token).userId);

    Defaults.userId = jwt_decode(token).userId;
    if (action?.action === 'joinEnterprise') {
     const res = await registerNewUserInEnterprise(
      action.enterpriseId,
      Defaults.userId
     );
     console.log('joined in enterp', res);
    }
   } else {
    navigation.navigate('Signin');
   }

   isWeb && Defaults.Modal.hide(<ModalWrapper />);
  } else {
   setError('code', { message: response.message });
  }
 };

 return (
  <KeyboardAwareScrollViewComponent>
   <ScreenContainer style={{ paddingBottom: 30 }}>
    <View
     style={[
      isWeb ? { flex: 1, paddingTop: 30, paddingHorizontal: 72 } : { flex: 1 },
     ]}>
     {isWeb && (
      <View style={{ alignSelf: 'center' }}>
       <Text style={WebStyles.verificationText}>Verification Code</Text>
      </View>
     )}
     <View style={{ marginTop: 24 }}>
      <Text style={[styles.signUpWithText, styles2.text1]}>
       Enter the code we sent to
      </Text>

      <Text style={[styles.signUpWithText, styles2.text2]}>{userEmail}</Text>
     </View>

     <ConfirmationCodeField
      name='code'
      onChange={(code) => setValue('code', code)}
      error={errors.code?.message}
     />

     <TextInput
      ref={inputRef}
      inputMode='numeric'
      onLayout={() => inputRef.current.focus()}
      style={{ opacity: 0, visibility: 'hidden' }}
     />

     <View style={{ marginTop: 'auto' }}>
      <Button
       title='Done'
       disabled={isSubmitting}
       bgColor={getColor('NORMAL_GREEN')}
       onPress={handleSubmit(onSubmit)}
      />
     </View>
    </View>
   </ScreenContainer>
   <KeyboardPaddingView />
  </KeyboardAwareScrollViewComponent>
 );
};

export default VerifyModal;

const styles2 = StyleSheet.create({
 text1: { textAlign: 'center', ...lineHeight.subTitle3 },
 text2: {
  color: getColor('NEUTRAL800'),
  marginTop: 8,
  marginBottom: 32,
  textAlign: 'center',
  ...lineHeight.subTitle3,
 },
});
