import axios from 'axios';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as Location from 'expo-location';
import * as Network from 'expo-network';
import { Alert, Linking } from 'react-native';

import { Defaults } from './defaults';
import { isWeb } from './platform';

import authStore from 'store/authStore';
import geoFence from 'store/geoFence';
import treeFilter from 'store/treeFilter';

import { getGeofences } from 'services/mapServices';
import { getAllTrees, getEnterpriseTrees } from 'services/treeServices';
import { getProfile, getUserPermissions } from 'services/userService';

if (!isWeb) {
 var { AccessToken, LoginManager } = require('react-native-fbsdk-next');
}

const getUser = async () => {
 const response = await getProfile(Defaults.userId);

 if (response.type === 'success') {
  authStore.profile = response.user;
 }
};

const getPermissions = async (userId) => {
 const permissions = await getUserPermissions(userId);

 if (permissions.type === 'success') {
  authStore.permissions = permissions.permission;
 }
};

const goToSettings = () =>
 Alert.alert(
  '',
  'You do not have a location permission. Go to application settings and enable it manually.',
  [
   {
    text: 'Not right now',
    onPress: () => console.log('Cancel Pressed'),
    style: 'cancel',
   },
   {
    text: 'Go to settings',
    onPress: () => Linking.openSettings(),
   },
  ]
 );

const getLocationPermission = async () => {
 const { status } = await Location.requestForegroundPermissionsAsync();

 if (status !== 'granted') {
  goToSettings();
  return status;
 }

 const getCurrentPosition = await getCurrentPositionAsync();

 return getCurrentPosition;
};

const getCurrentPositionAsync = async () => {
 const last = await Location.getLastKnownPositionAsync();

 if (last) {
  return last;
 } else {
  const { coords } = await Location.getCurrentPositionAsync({});
  return coords;
 }
};

const getAppleCredentials = async () => {
 const isAvailableAsync = await AppleAuthentication.isAvailableAsync();

 if (isAvailableAsync) {
  const credential = await AppleAuthentication.signInAsync({
   requestedScopes: [
    AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
    AppleAuthentication.AppleAuthenticationScope.EMAIL,
   ],
  });

  return credential;
 } else {
  Alert.alert('Error happened');
 }
};

const getFbProfile = async () => {
 const result = await AccessToken.getCurrentAccessToken();

 const res = await axios.get(
  `https://graph.facebook.com/${result.userID}?fields=name,email,picture&access_token=${result.accessToken}`
 );

 if (res.data.email) {
  const data = {
   firstName: res.data.name.split(' ')[0],
   lastName: res.data.name.split(' ')[1],
   email: res.data.email,
  };

  return data;
 } else {
  Alert.alert('There is no email in your profile');
 }
};

const getFacebookUserData = async () => {
 await LoginManager.logInWithPermissions(['public_profile', 'email']);

 const res = await getFbProfile();

 return res;
};

const getGeofence = async () => {
 const response = await getGeofences();

 geoFence.geoFences = response;
};

const getNetworkStatus = async () => {
 const status = await Network.getNetworkStateAsync();

 return !status.isInternetReachable;
};

const getTrees = async () => {
 const res = await getAllTrees();
 if (res.type === 'success') {
  treeFilter.treeCount = res.count;
 }
};

const getEnterpriseTree = async (enterpriseId, userId) => {
 const res = await getEnterpriseTrees(enterpriseId, userId);
 if (res.type === 'success') {
  treeFilter.enterpriseTreeCount = res.count;
 }
};

export {
 getUser,
 getAppleCredentials,
 getPermissions,
 getNetworkStatus,
 getFacebookUserData,
 getGeofence,
 getTrees,
 getEnterpriseTree,
 getLocationPermission,
 goToSettings,
 getCurrentPositionAsync,
};
