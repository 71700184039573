import React, { useEffect, useRef } from 'react';
import { Keyboard, Platform, Animated } from 'react-native';

// import Animated, { EasingNode } from 'react-native-reanimated';

export default function KeyboardPaddingView({ modifier = -18 }) {
 const height = useRef(new Animated.Value(0)).current;
 const keyboardAnimationDuration = useRef();

 function animateKeyboard(toValue, duration) {
  if (!keyboardAnimationDuration.current) {
   keyboardAnimationDuration.current = 290;
  }

  Animated.timing(height, {
   toValue,
   duration: keyboardAnimationDuration.current,
  }).start();
 }

 useEffect(() => {
  const keyboardWillShowListener = Keyboard.addListener(
   Platform.select({
    ios: 'keyboardWillShow',
    android: 'keyboardDidShow',
   }),
   ({ endCoordinates: { height } }) => {
    animateKeyboard(height + modifier, 285);
   }
  );
  const keyboardWillHideListener = Keyboard.addListener(
   Platform.select({
    ios: 'keyboardWillHide',
    android: 'keyboardDidHide',
   }),
   () => animateKeyboard(0)
  );
  return () => {
   keyboardWillShowListener.remove();
   keyboardWillHideListener.remove();
  };
 }, []);

 return (
  <Animated.View
   style={{
    height,
   }}
  />
 );
}
