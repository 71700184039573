import usePermission from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import AddTreeWeb from './AddTreeWeb';
import Alert from './Alert';
import RemoveModal from './RemoveModal';
import UserImageContainer from './UserImageContainer';

import treeFilter from 'store/treeFilter';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { deleteTree, getTreeInfo } from 'services/treeServices';

import ModalWrapper from 'components/modals/ModalWrapper';
import Icon from 'components/reusable/Icon';
import WebTreeInfo from 'components/webComps/webcommon/WebTreeInfo';

import { getEnterpriseTree, getTrees } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';
import { InfoPolygon, WebClose, WebTrash } from 'utils/svgs';

const WebTreeInfoMini = ({
 data,
 setHoverInfo,
 getClusters,
 embededMapParams,
 isEmbededMap,
}) => {
 const [tree, setTree] = useState({});
 const { profile } = useProfile();
 const { permissions } = usePermission();
 const [loaded, setLoaded] = useState(false);

 const userId = profile?._id || embededMapParams.userId;
 const enterpriseId = profile?.enterprise || '';

 const treeGeometry = data?.feature?.geometry?.coordinates;
 const customTreeGeometry = data?.feature?.properties?.coordinates
  ? JSON.parse(data?.feature?.properties?.coordinates)
  : undefined;

 const treeLat = customTreeGeometry ? customTreeGeometry[0] : treeGeometry[0];
 const treeLng = customTreeGeometry ? customTreeGeometry[1] : treeGeometry[1];

 const getTree = async () => {
  const response = await getTreeInfo(treeLat, treeLng);
  if (response.message === 'Tree fetched successfully') {
   setTree(response.tree);
   setLoaded(true);
  }
 };

 useEffect(() => {
  getTree();
 }, [data]);

 const species =
  tree?.properties?.species.charAt(0).toUpperCase() +
  tree?.properties?.species.slice(1);

 const openModal = () => {
  setHoverInfo(false);

  Defaults.Modal.show(
   <ModalWrapper
    fullScreenModal
    customStyles={WebStyles.infoModalWrapperStyle}
    isEmbededMap={isEmbededMap}>
    <WebTreeInfo
     isEmbededMap={isEmbededMap}
     getClusters={getClusters}
     coordinates={treeGeometry || customTreeGeometry}
    />
   </ModalWrapper>
  );
 };

 const editTree = () => {
  setHoverInfo();
  Defaults.Modal.show(
   <ModalWrapper fullScreenModal customStyles={WebStyles.infoModalWrapperStyle}>
    <AddTreeWeb editMode data={tree} getClusters={getClusters} />
   </ModalWrapper>
  );
 };

 const removeTree = async () => {
  const res = await deleteTree(tree._id, Defaults.userId);
  if (res.message === 'Tree deleted successfully.') {
   getEnterpriseTree(profile.enterprise, profile._id);
   getTrees();
   setHoverInfo(null);
   treeFilter.pin = [];
   getClusters();
   Defaults.Modal.hide(<ModalWrapper />);
   setTimeout(() => {
    Defaults.Modal.show(<Alert alert={res.message} />);
   }, 500);
  }
 };

 const removeTreeModal = async () => {
  Defaults.Modal.show(
   <ModalWrapper customStyles={WebStyles.removeTreeWrapper}>
    <RemoveModal remove={removeTree} />
   </ModalWrapper>
  );
 };

 return (
  <View
   style={{
    flexDirection: 'column',
    position: 'absolute',
    left: data.x - 168,
    top: data.y + 25,
    width: 335,
    zIndex: 1,
   }}>
   {loaded && (
    <>
     <View
      style={[
       {
        position: 'relative',
        backgroundColor: getColor('WHITE'),
        borderRadius: 12,
        width: 335,
        padding: 8,
        flexDirection: 'row',
       },
      ]}>
      <TouchableOpacity
       style={{
        position: 'absolute',
        right: 10,
        top: 10,
        zIndex: 1,
       }}
       onPress={() => {
        setHoverInfo(null);
        treeFilter.pin = [];
       }}>
       <WebClose />
      </TouchableOpacity>
      <UserImageContainer
       image={
        tree?.imgUrls?.length > 0
         ? tree?.imgUrls[0]?.uri || tree?.imgUrls[0]
         : IMAGES.tremapWebSigninImg
       }
       otherStyles={{ width: 116, height: 126 }}
      />
      <View
       style={{ marginLeft: 12, marginTop: 10, flex: 1, paddingRight: 10 }}>
       <View style={{ minHeight: 25 }}>
        <Text style={WebStyles.userNameText}>{tree?.properties?.common}</Text>
       </View>
       <View style={{ minHeight: 25 }}>
        <Text style={WebStyles.url}>
         {tree?.properties?.genus} {species}
        </Text>
       </View>
      </View>
     </View>
     <View
      style={{
       width: 148,
       height: 52,
       backgroundColor: getColor('WHITE'),
       marginTop: 8,
       borderRadius: 12,
       alignSelf: 'center',
       alignItems: 'center',
       justifyContent: 'space-evenly',
       flexDirection: 'row',
      }}>
      <TouchableOpacity onPress={openModal}>
       <Icon
        name='fullscreen'
        style={{ fontSize: 24, color: getColor('NEUTRAL500') }}
       />
      </TouchableOpacity>
      {!isEmbededMap &&
      (permissions === 'Admin' || permissions === 'Editors') &&
      (enterpriseId
       ? enterpriseId === tree?.organizationId
       : null || tree?.userId === userId) ? (
       <>
        <TouchableOpacity onPress={editTree}>
         <Icon
          name='edit'
          style={{ fontSize: 24, color: getColor('NEUTRAL500') }}
         />
        </TouchableOpacity>
        <TouchableOpacity onPress={removeTreeModal}>
         <WebTrash color={getColor('NEUTRAL500')} />
        </TouchableOpacity>
       </>
      ) : null}
     </View>
     <View style={{ alignSelf: 'center' }}>
      <InfoPolygon />
     </View>
    </>
   )}
  </View>
 );
};

export default WebTreeInfoMini;
