import React from 'react';
import { useForm } from 'react-hook-form';
import { Alert, StyleSheet, View } from 'react-native';
import { useSnapshot } from 'valtio';

import authStore from 'store/authStore';

import { changeEmail } from 'services/index';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import ControllerInput from 'components/reusable/ControllerInput';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import KeyboardAwareScrollViewComponent from 'components/reusable/KeyboardAwareScrollViewComponent';
import KeyboardPaddingView from 'components/reusable/KeyboardPaddingView';
import { changeEmailSchema } from 'components/validations/schema';

import Button from 'reusable/Button';
import ScreenContainer from 'reusable/ScreenContainer';
import Text from 'reusable/Text';

import { isAndroid } from 'utils/platform';
import { getColor } from 'utils/settings';

const ChangeEmail = ({ navigation }) => {
 const store = useSnapshot(authStore);

 const resolver = useYupValidationResolver(changeEmailSchema);

 const {
  control,
  setError,
  handleSubmit,
  formState: { errors, isSubmitting },
 } = useForm({
  resolver,
  defaultValues: {
   email: '',
  },
 });

 const onSubmit = async (values) => {
  const obj = {
   userId: store.profile._id,
   oldEmail: store.profile.email,
   newEmail: values.email,
  };

  const response = await changeEmail({
   ...obj,
   typeOfAction: 'sendCode',
  });

  if (response.type === 'success') {
   navigation.navigate('ConfirmationEmail', obj);
  } else {
   setError('email', { message: response.message });
  }
 };

 return (
  <KeyboardAwareScrollViewComponent>
   <FocusAwareStatusBar
    barStyle={isAndroid ? 'dark-content' : 'light-content'}
   />
   <ScreenContainer>
    <Text style={styles2.centerText}>Enter new E-mail Address</Text>

    <View style={{ marginBottom: 24 }}>
     <ControllerInput
      name='email'
      control={control}
      iconName='envelope'
      placeholder='Email Address'
      keyboardType='email-address'
      error={errors.email?.message}
      inputStyles={{ marginBottom: 10 }}
     />
    </View>

    <View style={{ marginTop: 'auto', marginBottom: 30 }}>
     <Button
      title='Confirm'
      disabled={isSubmitting}
      bgColor={getColor('NORMAL_GREEN')}
      onPress={handleSubmit(onSubmit)}
     />
    </View>
   </ScreenContainer>
   <KeyboardPaddingView />
  </KeyboardAwareScrollViewComponent>
 );
};

export default ChangeEmail;

const styles2 = StyleSheet.create({
 centerText: {
  marginBottom: 24,
  marginTop: 40,
  textAlign: 'center',
 },
 container: {
  flex: 1,
  marginBottom: 50,
  justifyContent: 'flex-start',
 },
});
