import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import useProfile from 'hooks/useProfile';
import React, { useEffect, useState } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { useSnapshot } from 'valtio';

import data from '../../../data.json';
import EmbedMap from './EmbedMap/index.web';
import NavContainer from './NavContainer';
import SecondMap from './SecondMap/index.web';
import ThirdMap from './ThirdMap/index.web';
import WebHomeScreen from './WebHomeScreen/WebHomeScreen';
import WebMapOld from './WebMap/indexmap';
import WebPasswordReset from './WebResetPassword/WebPasswordReset';
import WebSigninScreen from './WebSignInScreen/WebSigninScreen';
import WebPPSignup from './WebSignUpScreen/WebPPSignup';

import authStore from 'store/authStore';

import { getProfile } from 'services/userService';

import ModalProvider from 'components/modals/ModalProvider';
import Billing from 'components/webComps/webcommon/Billing';
import CollectionManagement from 'components/webComps/webcommon/CollectionManagement';
import Loading from 'components/webComps/webcommon/Loading';
import Users from 'components/webComps/webcommon/Users';

import { getPermissions } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { storageGetString, storageSet } from 'utils/mmkvStorage';

const options = {
 headerShown: false,
};

const Stack = createNativeStackNavigator();
const Drawer = createBottomTabNavigator();

const linking = {
 prefixes: [],
 config: {
  screens: {
   Auth: {
    path: '',
    screens: {
     Signin: '/signin',
     Signup: '/signup',
     PasswordReset: '/reset',
    },
   },
   Home: {
    path: 'home',
    screens: {
     MapScreen: 'map',
     Collection: 'collection',
     Users: 'users',
     Billing: 'billing',
    },
   },
   Map: { path: '/portalmap' },
   EmbedMap: { path: '/embedmap' },
   SecondMap: { path: '/secondMap' },
   ThirdMap: { path: '/thirdMap' },
  },
 },
};

const AuthStack = () => {
 return (
  <Stack.Navigator
   screenOptions={{
    ...options,
   }}>
   <Stack.Screen
    options={{ title: 'Tremap Signin' }}
    name='Signin'
    component={WebSigninScreen}
   />
   <Stack.Screen
    options={{ title: 'Tremap Signup' }}
    name='Signup'
    component={WebPPSignup}
   />
   <Stack.Screen
    options={{ title: 'Tremap Password Reset' }}
    name='PasswordReset'
    component={WebPasswordReset}
   />
  </Stack.Navigator>
 );
};

const HomeStack = () => {
 const [loaded, isLoaded] = useState(false);
 const { profile } = useProfile();

 const getUser = async () => {
  const response = await getProfile(Defaults.userId);

  if (response.type === 'success') {
   authStore.profile = response.user;
   isLoaded(true);
   getPermissions(Defaults.userId);
  }
 };

 useEffect(() => {
  getUser();
  if (
   !storageGetString('genusData') ||
   JSON.parse(storageGetString('genusData'))[0].genus !== 'Other'
  ) {
   storageSet('genusData', JSON.stringify(data));
  }
 }, []);

 return (
  <>
   {loaded ? (
    <Drawer.Navigator
     screenOptions={{ ...options }}
     tabBar={(props) => <NavContainer {...props} />}>
     <Drawer.Screen
      options={{ title: 'Tremap Home - Map' }}
      name='MapScreen'
      component={WebHomeScreen}
     />
     <Drawer.Screen
      options={{
       title: 'Tremap Home - Collection Management',
       unmountOnBlur: true,
      }}
      name='Collection'
      component={CollectionManagement}
     />
     {profile.enterpriseData ? (
      <Drawer.Screen
       options={{ title: 'Tremap Home - Users' }}
       name='Users'
       component={Users}
      />
     ) : null}
     <Drawer.Screen
      options={{ title: 'Tremap Home - Billing' }}
      name='Billing'
      component={Billing}
     />
    </Drawer.Navigator>
   ) : (
    <Loading />
   )}
  </>
 );
};

const WebNavigation = () => {
 const store = useSnapshot(authStore);

 return (
  <GestureHandlerRootView style={{ flex: 1 }}>
   <NavigationContainer linking={linking}>
    <Stack.Navigator
     screenOptions={{
      ...options,
     }}>
     {store.isLoggedIn ? (
      <Stack.Screen
       name='Home'
       options={{ title: 'Tremap Home' }}
       component={HomeStack}
      />
     ) : (
      <Stack.Screen
       name='Auth'
       options={{ title: 'Tremap Auth' }}
       component={AuthStack}
      />
     )}

     <Stack.Screen name='Map' component={WebMapOld} />
     <Stack.Screen name='EmbedMap' component={EmbedMap} />
     <Stack.Screen name='SecondMap' component={SecondMap} />
     <Stack.Screen name='ThirdMap' component={ThirdMap} />
    </Stack.Navigator>
   </NavigationContainer>

   <ModalProvider ref={(ref) => (Defaults.Modal = ref)} />
  </GestureHandlerRootView>
 );
};

export default WebNavigation;
